import { List, ListItem, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import useDataFetch from "../../hooks/useDataFetch";

export default function DetailView() {
  const { id } = useParams();
  const reviewId = parseInt(id);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(true);
  const dataFetching = useDataFetch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataFetching(`getentry/${reviewId}`);
      setReview(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  console.log(review);
  return (
    <div>
      <div></div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {/*<Box sx={{ height: 320, transform: "translateZ(0px)", flexGrow: 1 }}>*/}
          <List>
            <div /*style={{maxHeight: "800px", maxWidth: "800px"}}*/>
              <img
                src={review.picture_url}
                alt=""
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  height: "auto",
                  maxHeight: "700px",
                  objectFit: "contain",
                }}
              />
            </div>
            <ListItem>
              {review.brewery} {review.beerName}
              {/*<IconButton>
              <StarsIcon/>
            </IconButton>*/}
            </ListItem>

            <Divider></Divider>
            <ListItem>{review.user_name}</ListItem>
            <ListItem>{review.rating}</ListItem>
            <ListItem>{review.category}</ListItem>
            <Divider></Divider>
            <ListItem>{review.longReview}</ListItem>
          </List>
          {/*<SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{ position: "fixed", bottom: 16, right: 16, marginTop: "2%"}}
              icon={<SpeedDialIcon />}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                />
              ))}
            </SpeedDial>*/}
        </div>
      )}
    </div>
  );
}
