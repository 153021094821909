import FadeLoader from "react-spinners/FadeLoader";

const Loader = () => {
  return (
    <FadeLoader
      color="#1976d2"
      cssOverride={{ display: "block", margin: "25% auto" }}
    />
  );
};

export default Loader;