import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import EditableReview from "../../components/ReviewList/editablereview";
import useDataFetch from "../../hooks/useDataFetch";

const MyReviewListPage = () => {
  const dataFetching = useDataFetch();
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = parseInt(sessionStorage.getItem("userId"));

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataFetching(`userreviews/${userId}`);
      setReviews(data);
      setLoading(false);
    };
    fetchData();
  }, [userId]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="home">
          {reviews && <EditableReview reviews={reviews} />}
        </div>
      )}
    </div>
  );
};

export default MyReviewListPage;
