import { TextField, Button, Alert} from "@mui/material";
import React, { useState, useEffect } from "react";
import { storage } from "../../cloudstorage/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Loader from "../../components/loader/loader";
import useRefreshToken from "../../hooks/useRefreshToken";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const NewReview = () => {
  const [draftState, setDraft] = useState(false);

  function handleDraft() {
    if (draftState) {
      setBeer(sessionStorage.getItem("beer"));
      setBrewery(sessionStorage.getItem("brewery"));
      setRating(sessionStorage.getItem("rating"));
      setCategory(sessionStorage.getItem("category"));
      setShortReview(sessionStorage.getItem("shortReview"));
      setLongReview(sessionStorage.getItem("longReview"));
    }
  }

  function handleBeer(beer) {
    sessionStorage.removeItem("draft");
    if (beer === "") {
      sessionStorage.setItem("draft", false);
    } else {
      sessionStorage.setItem("draft", true);
    }
    setBeer(beer);
    sessionStorage.setItem("beer", beer);
  }

  function handleBrewery(brewery) {
    sessionStorage.removeItem("draft");
    if (brewery === "") {
      sessionStorage.setItem("draft", false);
    } else {
      sessionStorage.setItem("draft", true);
    }
    setBrewery(brewery);
    sessionStorage.setItem("brewery", brewery);
  }

  function handleRating(rating) {
    sessionStorage.removeItem("draft");
    if (rating === "") {
      sessionStorage.setItem("draft", false);
    } else {
      sessionStorage.setItem("draft", true);
    }
    setRating(rating);
    sessionStorage.setItem("rating", rating);
  }

  function handleCategory(category) {
    sessionStorage.removeItem("draft");
    if (category === "") {
      sessionStorage.setItem("draft", false);
    } else {
      sessionStorage.setItem("draft", true);
    }

    setCategory(category);
    sessionStorage.setItem("category", category);
  }

  function handleShortReview(shortReview) {
    sessionStorage.removeItem("draft");
    if (shortReview === "") {
      sessionStorage.setItem("draft", false);
    } else {
      sessionStorage.setItem("draft", true);
    }

    setShortReview(shortReview);
    sessionStorage.setItem("shortReview", shortReview);
  }

  function handleLongReview(longReview) {
    sessionStorage.removeItem("draft");
    if (longReview === "") {
      sessionStorage.setItem("draft", false);
    } else {
      sessionStorage.setItem("draft", true);
    }

    setLongReview(longReview);
    sessionStorage.setItem("longReview", longReview);
  }

  useEffect(() => {
    const draft = sessionStorage.getItem("draft");
    if (draft === null) {
      sessionStorage.setItem("draft", false);
      console.log(draftState);
    } else if (draft === "true") {
      setDraft(true);
      console.log(draftState);
    } else if (draft === "false") {
      setDraft(false);
      console.log(draftState);
    }
    handleDraft();
  }, [draftState]);
  const refresh = useRefreshToken();
  const userId = parseInt(sessionStorage.getItem("userId"));
  const [beer, setBeer] = useState("");
  const [beerError, setBeerError] = useState(false);
  const [brewery, setBrewery] = useState("");
  const [breweryError, setBreweryError] = useState(false);
  const [rating, setRating] = useState("");
  const [ratingError, setRatingError] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [shortReview, setShortReview] = useState("");
  const [shortReviewError, setShortReviewError] = useState(false);
  const [longReview, setLongReview] = useState("");
  const [longReviewError, setLongReviewError] = useState(false);
  const [imageUpload, setImageUpload] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileError, setFileError] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setBeerError(false);
    setBreweryError(false);
    setRatingError(false);
    setCategoryError(false);
    setShortReviewError(false);
    setLongReviewError(false);

    if (brewery === "" || brewery === null) {
      setBreweryError(true);
    }

    if (beer === "" || beer === null) {
      setBeerError(true);
    }

    if (rating === "" || rating === null) {
      setRatingError(true);
    }

    if (category === "" || category === null) {
      setCategoryError(true);
    }

    if (shortReview === "" || shortReview === null) {
      setShortReviewError(true);
    }

    if (longReview === "" || longReview === null) {
      setLongReviewError(true);
    }

    if (!imageUpload) {
      setFileError(true);
      return;
    }

    if (
      userId &&
      beer &&
      brewery &&
      rating &&
      category &&
      shortReview &&
      longReview &&
      !fileError
    ) {
      if (rating > 10 || rating < 0) {
        setRatingError(true);
      } else {
        await sendValue();
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
        clearFields();
      }
    }
  }

  function clearFields() {
    setBeer("");
    setBrewery("");
    setLongReview("");
    setShortReview("");
    setRating("");
    setCategory("");
    setImageUpload(null);
  }

  async function postData(formData, tries) {
    if (tries > 3) {
      window.location.replace("/");
      return;
    }
    const dataRequest = await fetch(`https://apidevelopment.de/newentry`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    if (dataRequest.status === 401) {
      const token = await refresh();
      console.log(token);
      sessionStorage.setItem("token", token);
      return postData(formData, tries + 1);
    } else {
      const data = await dataRequest.json();
      setLoading(false);
      sessionStorage.removeItem("draft");
      sessionStorage.setItem("draft", false);
      sessionStorage.removeItem("beer");
      sessionStorage.removeItem("brewery");
      sessionStorage.removeItem("rating");
      sessionStorage.removeItem("category");
      sessionStorage.removeItem("shortReview");
      sessionStorage.removeItem("longReview");
      setDraft(false);
      return console.log(data);
    }
  }

  function handleImageUpload(file) {
    setImageUpload(file);
    setFileError(false);
  }
  async function sendValue() {
    const form = document.querySelector("Form");
    form.reset();
    setLoading(true);
    const imageRef = ref(storage, `images/${imageUpload.name}`);

    uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
      const url = await getDownloadURL(snapshot.ref);

      const formData = {
        userId: userId,
        rating: rating,
        longReview: longReview,
        beerName: beer,
        brewery: brewery,
        category: category,
        shortReview: shortReview,
        pictureUrl: url,
      };

      await postData(formData, 0);
    });
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Form">
          <div>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                id="outlined-basic"
                label="Bier"
                value={beer}
                variant="outlined"
                onChange={(e) => handleBeer(e.target.value)}
                fullWidth
                required
                error={beerError}
                //className={classes.field}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <TextField
                id="outlined-basic"
                label="Brauerei"
                variant="outlined"
                onChange={(e) => handleBrewery(e.target.value)}
                fullWidth
                required
                value={brewery}
                error={breweryError}
                //className={classes.field}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <TextField
                id="outlined-basic"
                label="Rating"
                variant="outlined"
                onChange={(e) => handleRating(e.target.value)}
                fullWidth
                required
                type="number"
                placeholder="Zahlenwert bis 10"
                value={rating}
                error={ratingError}
                style={{ marginTop: 10, marginBottom: 10 }}
                //className={classes.field}
              />
              {/*<Select
            value={rating}
            fullWidth
            onChange={(e) => {setRating(e.target.value)}}
            label="Rating"
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={1.5}>1.5</MenuItem>
          <MenuItem value={2}>2</MenuItem>
        </Select>*/}

              {/*<Stack spacing={1}>
        <Rating
        defaultValue={0}
        precision={1}
        />
        </Stack>*/}
              {/*<FreeSoloCreateOptionDialog/>*/}
              {/* AutoComplete Test1
        <Autocomplete
            disablePortal
            freeSolo
            required
            id="combo-box"
            options={categories}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Kategorie"/>}
            onChange={(e, value) => setCategory(value)} 
            filterOptions={(categories, params) => {
                const filtered = filter(categories, params);
      
                if (params.inputValue !== '') {
                  filtered.push(
                     `Add "${params.inputValue}"`
                   /* inputValue: params.inputValue,
                    
                    `,
                );
                }
                return filtered;
            }}
            //onChange={(e, value) => console.log(value)} 
        />*/}
              <TextField
                id="outlined-basic"
                label="Kategorie"
                variant="outlined"
                onChange={(e) => handleCategory(e.target.value)}
                fullWidth
                required
                value={category}
                error={categoryError}
                style={{ marginTop: 10, marginBottom: 10 }}
                //className={classes.field}
              />
              <TextField
                id="outlined-basic"
                label="Kurzreview"
                variant="outlined"
                onChange={(e) => handleShortReview(e.target.value)}
                fullWidth
                required
                value={shortReview}
                error={shortReviewError}
                style={{ marginTop: 10, marginBottom: 10 }}
                //className={classes.field}
              />
              <TextField
                id="outlined-basic"
                label="Langes Review"
                variant="outlined"
                onChange={(e) => handleLongReview(e.target.value)}
                fullWidth
                required
                multiline
                value={longReview}
                rows={5}
                error={longReviewError}
                style={{ marginTop: 10, marginBottom: 10 }}
                //className={classes.field}
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                style={{ marginTop: "2%" }}
              >
                Upload file
                <input
                  type="file"
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={(e) => {
                    handleImageUpload(e.target.files[0]);
                  }}
                />
              </Button>

              <Button
                variant="contained"
                type="submit"
                size="large"
                style={{ marginLeft: "3%", marginTop: "2%" }}
              >
                Speichern
              </Button>
            </form>
            {imageUpload ? (
              <Alert severity="info" style={{ marginTop: "2%" }}>
                {" "}
                File {imageUpload.name} uploaded
              </Alert>
            ) : null}
            {/*{categoryError? <Alert severity="warning">Keine Kategorie ausgewählt</Alert> : null}*/}
            {success ? (
              <Alert severity="success" style={{ marginTop: "2%" }}>
                Erfolgreich hochgeladen
              </Alert>
            ) : null}
            {ratingError ? (
              <Alert severity="error" style={{ marginTop: "2%" }}>
                Ungültiger Wert für die Bewertung. Darf nicht größer als 10 oder
                kleiner als 0 sein.
              </Alert>
            ) : null}
            {fileError ? (
              <Alert severity="error" style={{ marginTop: "2%" }}>
                Kein Foto ausgewählt
              </Alert>
            ) : null}
            {draftState ? (
              <Alert severity="info" style={{ marginTop: "2%" }}>
                Draft
              </Alert>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewReview;
