import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import Chart2 from "./rankingtemplateownchart";

export default function ChartWrapper() {
  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 500,
                  marginTop: "3%",
                }}
              >
                {/*<Chart />*/}
                {/*<Ranking/>*/}
                <Chart2 />
              </Paper>
              <Typography variant="h4" sx={{ marginTop: "3%" }}>
                Aktuelle Top 5
              </Typography>
            </Grid>
            {/* Recent Deposits */}
            {/*<Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
              </Grid>*/}
            {/* Recent Orders */}
            {/*<Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Orders />
                </Paper>
              </Grid>*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
