import { Route, Routes } from "react-router";
import './App.css';
import ResponsiveAppBar from "./components/responsivenavbar"; //macht iwie probleme
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import ProtectedRoute from './components/auth/ProtectedRoutes';
import useAuth from './hooks/useAuth';
import ReviewListPage from "./pages/AllReviewsPage/ReviewListPage";
import DetailView from "./pages/DetailPage/DetailView";
import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/LoginPage/Login";
import MyReviewListPage from "./pages/MyReviewsPage/MyReviews";
import NewReview from './pages/NewEntryPage/NewReview';
import SignUp from "./pages/SignUpPage/SignUp";
import ChartWrapper from './pages/TopRanking.jsx/rankingwrapper';

const defaultTheme = createTheme(/*{
  palette: {
    mode: 'dark'
  }
}*/);

function App() {

  const {auth} = useAuth();

  




  //authenticated=false;

  return (
    <div className="App">
       <ThemeProvider theme={defaultTheme}>
        <CssBaseline/>
        <ResponsiveAppBar/>
        
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/signup" element={<SignUp/>}/>

          <Route path="home" element={<ProtectedRoute auth={auth}>
          <HomePage/>
          </ProtectedRoute>}/>

          <Route path="AlleReviews" element={<ProtectedRoute auth={auth}>
          <ReviewListPage/>
          </ProtectedRoute>}/>

          <Route path="MeineReviews" element={<ProtectedRoute auth={auth}>
          <MyReviewListPage/>
          </ProtectedRoute>}/>

          <Route path="Reviews" element={<ProtectedRoute auth={auth}>
          <NewReview/>
          </ProtectedRoute>}/>

          <Route path="review/:id" element={<ProtectedRoute auth={auth}>
          <DetailView/>
          </ProtectedRoute>}/>

          <Route path="Ranking" element={<ProtectedRoute auth={auth}>
          {/*<Ranking/>*/}
          <ChartWrapper/>
          </ProtectedRoute>}/>

        </Routes>
        {/*<AuthWrapper/>*/}
      <div className="content">
      </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
