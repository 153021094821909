//import './beerreview.css'
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  Alert,
  Snackbar,
  Box,
  CircularProgress
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import { blue, red } from "@mui/material/colors";
import { storage } from "../../cloudstorage/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useRefreshToken from "../../hooks/useRefreshToken";

const EditableReview = ({ reviews }) => {
  const refresh = useRefreshToken();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fileDialog, setFileDialog] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [editSnack, setEditSnack] = useState(false);
  const [deleteSnack, setDeleteSnack] = useState(false);
  const [fileUpload, setFileUpload] = useState();
  const [index, setIndex] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (review, i) => {
    setSelectedReview(review);
    setIndex(i);
    setOpen(true);
  };

  const handleClickClose = () => {
    setSelectedReview(null);
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setEditSnack(false);
    setDeleteSnack(false);
  };

  async function postUpdatedData(formJson){
   return await fetch("https://apidevelopment.de/update", {
              method: "PUT",
              mode: "cors",
              headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formJson),
            });
  }

  async function deleteReview(){
    return await fetch("https://apidevelopment.de/delete", {
                mode: "cors",
                method: "PUT",
                headers: {
                  Authorization: sessionStorage.getItem("token"),
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  reviewId: selectedReview.review_id,
                }),
              });
  }

  return (
    <>
      <List className="review-list">
        {reviews.map((review, i) => (
          <>
            <Link
              to={`/review/${review.review_id}`}
              style={{ textDecoration: "none" /*, color: "black"*/ }}
            >
              <ListItem
                className="review-preview"
                key={i}
                secondaryAction={
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickOpen(review, i);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={review.picture_url} />
                </ListItemAvatar>

                <ListItemText
                  primary={review.brewery + " " + review.beerName}
                  secondary={review.shortReview}
                />
              </ListItem>
              <Divider></Divider>
            </Link>
          </>
        ))}
      </List>
      <Dialog
        open={open}
        onClose={handleClickClose}
        Back
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            setLoading(true);
            const formData = new FormData(event.currentTarget);
            const userId = sessionStorage.getItem("userId");
            const formJson = Object.fromEntries(formData.entries());
            formJson.userId = userId;
            formJson.review_id = selectedReview.review_id;
            const res = await postUpdatedData(formJson)
            
            if(res.status  === 401){
                await refresh();
                const res = await postUpdatedData(formJson);
                const response = await res.json()
                setMessage(response.message);
            console.log(formJson);
            setEditSnack(true);
            reviews[index].brewery = formJson.brewery;
            reviews[index].beerName = formJson.beerName;
            reviews[index].category = formJson.category;
            reviews[index].rating = formJson.rating;
            reviews[index].shortReview = formJson.shortReview;
            reviews[index].longReview = formJson.longReview;
            console.log(index);
            handleClickClose();
            setLoading(false);
            return;
            }
                const response = await res.json()
                setMessage(response.message);
            console.log(formJson);
            setEditSnack(true);
            reviews[index].brewery = formJson.brewery;
            reviews[index].beerName = formJson.beerName;
            reviews[index].category = formJson.category;
            reviews[index].rating = formJson.rating;
            reviews[index].shortReview = formJson.shortReview;
            reviews[index].longReview = formJson.longReview;
            console.log(index);
            handleClickClose();
            setLoading(false)
          },
        }}
      >
        <DialogTitle>
          Bearbeiten
          <IconButton
            onClick={() => {
              setFileDialog(true);
            }}
            sx={{
              position: "absolute",
              right: 50,
              top: 8,
              color: blue[600],
            }}
          >
            <CloudUpload />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setDialogOpen(open);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: red[400],
            }}
          >
            <DeleteIcon
              sx={{
                color: red,
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="brewery"
            label="Brauerei"
            fullWidth
            defaultValue={selectedReview ? selectedReview.brewery : null}
            variant="standard"
          />
          <TextField
            label="Bier"
            name="beerName"
            variant="standard"
            fullWidth
            defaultValue={selectedReview ? selectedReview.beerName : null}
          />
          <TextField
            label="Rating"
            variant="standard"
            name="rating"
            fullWidth
            defaultValue={selectedReview ? selectedReview.rating : null}
          />
          <TextField
            label="Kategorie"
            variant="standard"
            fullWidth
            name="category"
            defaultValue={selectedReview ? selectedReview.category : null}
          />
          <TextField
            label="Kurzreview"
            variant="standard"
            fullWidth
            name="shortReview"
            defaultValue={selectedReview ? selectedReview.shortReview : null}
          />
          <TextField
            label="Langes Review"
            variant="standard"
            fullWidth
            multiline
            name="longReview"
            rows={5}
            defaultValue={selectedReview ? selectedReview.longReview : null}
          />
        </DialogContent>
        <DialogActions>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button onClick={handleClickClose}>Abbrechen</Button>
          <Box sx={{ m: 1, position: 'relative' }}>
          <Button type="submit" variant="contained" disabled={loading}>
            Speichern
          </Button>
          {loading && 
          <CircularProgress
          size={24}
            sx={{
              color: blue[600],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}/>}
          </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} hideBackdrop onClose={handleClickClose}>
        <DialogTitle>Löschen</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: red[500],
            }}
          >
            Review unwiederruflich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={(e) => {
              setDialogOpen(false);
            }}
          >
            Abbrechen
          </Button>
          <Box sx={{ m: 1, position: 'relative' }}>
          
          <Button
            variant="contained"
            color="error"
            disabled={loading}
            onClick={async () => {
                setLoading(true)
                const response = await deleteReview();

                if(response.status === 401){
                    await refresh();
                    const response = await deleteReview();
                    const res = await response.json()
                    reviews.splice(index, 1);
                    setDeleteSnack(true)
                    setDialogOpen(false)
                    setOpen(false)
                    setMessage(res.message)
                    setLoading(false)
                }else{
                    const res = await response.json()
                    reviews.splice(index, 1);
                    setDeleteSnack(true)
                    setDialogOpen(false)
                    setOpen(false)
                    setMessage(res.message)
                    setLoading(false)
                }

              
            }}
          >
            Löschen
          </Button>
          {loading && 
          <CircularProgress
          size={24}
            sx={{
              color: red[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}/>}
          </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={fileDialog} hideBackdrop>
        <DialogTitle>Neues Bild hochladen</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            fullwidth
            InputProps={{
              readOnly: true,
            }}
            margin="dense"
            value={fileUpload ? fileUpload.name : null}
          ></TextField>
          <IconButton
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            style={{ marginTop: "2%" }}
          >
            <CloudUpload
              sx={{
                color: blue[600],
              }}
            />
            <input
              type="file"
              style={{
                clip: "rect(0 0 0 0)",
                clipPath: "inset(50%)",
                height: 1,
                overflow: "hidden",
                position: "absolute",
                bottom: 0,
                left: 0,
                whiteSpace: "nowrap",
                width: 1,
              }}
              onChange={(e) => {
                setFileUpload(e.target.files[0]);
              }}
            />
          </IconButton>
        </DialogContent>
        <DialogActions>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={(e) => {
              setFileDialog(false);
            }}
          >
            Abbrechen
          </Button>
          <Box sx={{ m: 1, position: 'relative' }}>
          
          <Button
            variant="contained"
            disabled={loading}
            onClick={(e) => {
                setLoading(true);
              const imageRef = ref(storage, `images/${fileUpload.name}`);
              //delete old picture
              uploadBytes(imageRef, fileUpload).then(async (snapshot) => {
                const url = await getDownloadURL(snapshot.ref);
                const body = {review_id: selectedReview.review_id,
                    picture_url: url}
                const res = await postUpdatedData(body);
                if(res.status === 401){
                    await refresh()
                    const res = await postUpdatedData(body);
                    const response = await res.json();
                    selectedReview.picture_url = url;
                    setMessage(response.message);
                    setFileDialog(false)
                    setEditSnack(true);
                    setLoading(false)
                }else{
                    console.log(res);
                    const response = await res.json();
                    console.log(response)
                    setMessage(response.message);
                    setFileDialog(false)
                    setEditSnack(true);
                    setLoading(false);
                }
              });
            }}
          >
            Speichern
          </Button>
          {loading && 
          <CircularProgress
          size={24}
            sx={{
              color: blue[600],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}/>}
          </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Snackbar open={editSnack} autoHideDuration={6000} onClose={handleClose} id="edit"
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Snackbar id="delete"
      open={deleteSnack}
      autoHideDuration={6000} onClose={handleClose}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert
        onClose={handleClose}
        severity="warning"
        variant="filled"
        sx={{ width: '100%' }}>
            {message}
        </Alert>

      </Snackbar>
    </>
  );
};

export default EditableReview;