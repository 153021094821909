import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/loader/loader";
import useDataFetch from "../../hooks/useDataFetch";

const HomePage = () => {
  const dataFetching = useDataFetch();

  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataFetching("latestentries");
      setReviews(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h1 style={{ marginTop: "2%", textAlign: "center" }}>
            Neue Bierreviews
          </h1>
          <Carousel data-bs-theme="dark" pause="false">
            {reviews &&
              reviews.map((review, i) => (
                <Carousel.Item key={i}>
                  <div
                    style={{
                      maxWidth: "900px",
                      maxHeight: "800px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Link to={`/review/${review.review_id}`}>
                      <img
                        src={review.picture_url}
                        alt=""
                        style={{
                          maxWidth: "800px",
                          maxHeight: "700px",
                          width: "800px",
                          height: "700px",
                          /*width: "100%", height: "100%",*/ objectFit:
                            "contain",
                        }}
                      />
                    </Link>
                  </div>
                </Carousel.Item>
              ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default HomePage;
