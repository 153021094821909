//import './beerreview.css'
import { Link } from "react-router-dom";
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';

const Review = ({ reviews }) => {
    return (
      <List className="review-list">
        {reviews.map((review, i) => (
          <Link to={`/review/${review.review_id}`} style={{textDecoration: "none" /*, color: "black"*/}}>
          <ListItem className="review-preview" key={i} 
          >
            <ListItemAvatar>
              <Avatar src={review.picture_url}/>

            </ListItemAvatar>

            <ListItemText 
            primary={review.brewery + " " + review.beerName}  
            secondary={review.shortReview}
            />
               
          
               
               {/* <h2 id="heading">{ review.brewery } { review.beerName }</h2>
                    
            <p> { review.user_name }</p>
            <p> { review.shortReview }</p> 
            <p className="bottom"> { review.rating }</p> */}
          </ListItem>
          <Divider>
              
              </Divider>
          </Link>
        ))}
      </List>
    );
  }
   
  export default Review;