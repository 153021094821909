import useRefreshToken from "./useRefreshToken";

const useDataFetch = () => {
    const refresh = useRefreshToken();

    const dataFetching = async (url) => {
        let dataRequest = await fetchData(url);
        let tokenRequest;
        let jsonData;
        if(dataRequest.status === 401) {
            tokenRequest = await refresh();
            if(!tokenRequest.ok) return window.location.replace("/");
            dataRequest = await fetchData(url);
        }
        jsonData = await dataRequest.json();
        return jsonData;
    }
    return dataFetching;
}

const fetchData = async (url) => {
    const options = {
        mode: "cors",
        headers: {
            "Authorization" : sessionStorage.getItem("token"),
        }
    }
    
    const res = await fetch(`https://apidevelopment.de/${url}`, options);
    return res;
}

export default useDataFetch;