import * as React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loader from "../../components/loader/loader";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Alert } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  const { setAuth } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [usernameError, setUserNameError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [helperTextPassword, setHelperTextPassword] = React.useState();
  const [helperTextUsername, setHelperTextUsername] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState();
  //  const { login } = AuthData();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const username = data.get("email");
    const password = data.get("password");

    if (username !== "" && password !== "" && password.length >= 8) {
      try {
        setLoading(true);
        //await login(username, password)
        const res = await fetch("https://apidevelopment.de/validateuser", {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: username,
            password: password,
          }),
        });

        const response = await res.json();
        const token = response.token;

        if (response.token) {
          sessionStorage.setItem("token", response.token);
          sessionStorage.setItem("userId", response.userId);
          console.log(sessionStorage.getItem("token"));
          console.log(sessionStorage.getItem("userId"));
          setAuth({ username, token });
          setLoading(false);
          navigate("home");
        } else {
          setLoading(false);
          setError(true);
          setPasswordError(false);
          setUserNameError(false);
          setErrorText(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (username === "") {
      //alert("Username is empty");
      setHelperTextUsername("Username is empty");
      setUserNameError(true);
    } else if (password === "") {
      //alert("Password is empty")
      setHelperTextPassword("Password is empty");
      setPasswordError(true);
    } else if (password.length <= 8) {
      setHelperTextPassword("Password is at least 8 Characters long");
      setPasswordError(true);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              helperText={helperTextUsername}
              error={usernameError}
              id="email"
              label="Username"
              name="email"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              helperText={helperTextPassword}
              error={passwordError}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              {/*<Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
          </Grid>*/}
              <Grid item xs>
                <RouterLink
                  to={"/signup"}
                  style={{ textDecoration: "none", color: "#1976d2" }}
                >
                  {"Don't have an account? Sign Up"}
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {error ? (
          <Alert severity="error" style={{ marginTop: "3%" }}>
            {errorText}
          </Alert>
        ) : null}
        {/*<Copyright sx={{ mt: 8, mb: 4 }} />*/}
      </Container>
    </ThemeProvider>
  );
}
