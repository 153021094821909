import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth, userName } = useAuth();

    const refresh = async () => {
        const res = await fetch('https://apidevelopment.de/refreshtoken', {
            method: "Post",
            credentials: 'include',
            mode: "cors",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              "userName" : userName
            }
            )
          });
          if(res.status === 401){
            window.location.replace("/");
            return;
          }
          const response = await res.json();
        setAuth(prev => {
            //console.log(JSON.stringify(prev));
            
            return { ...prev, accessToken: response.token }
        });
        sessionStorage.removeItem("token");
        sessionStorage.setItem("token", response.token)
        return res;
    }
    return refresh;
};

export default useRefreshToken;