import * as React from "react";
import { BarChart } from "@mui/x-charts";
import Loader from "../../components/loader/loader";
import useDataFetch from "../../hooks/useDataFetch";

export default function Chart2() {
  const dataFetching = useDataFetch();
  const [loading, setLoading] = React.useState(true);
  const [beerNamesArray, setBeerNamesArray] = React.useState(null);
  const [beerRankingsArray, setBeerRankingsArray] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await dataFetching("calculated");
      handleData(data);
    };
    fetchData();

    function handleData(result) {
      if (result !== null) {
        for (var i = 0; i < result.length; i++) {
          for (var j = 0; j < result.length - i - 1; j++) {
            if (result[j]._avg.rating < result[j + 1]._avg.rating) {
              var temp = result[j];
              result[j] = result[j + 1];
              result[j + 1] = temp;
            }
          }
        }
        let beerNames = [];
        let beerRankings = [];
        let beerNamesSub = [];
        let beerRankingsSub = [];
        result.forEach((element) => {
          beerNamesSub.push(element.brewery + " " + element.beerName);
          beerRankingsSub.push(element._avg.rating);
        });

        for (let index = 0; index < 5; index++) {
          beerNames[index] = beerNamesSub[index];
          beerRankings[index] = beerRankingsSub[index];
        }
        setBeerNamesArray(beerNames);
        setBeerRankingsArray(beerRankings);
        setLoading(false);
        return;
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div style={{ width: "100%", flexGrow: 1, overflow: "auto" }}>
            <BarChart
              xAxis={[{ scaleType: "band", data: beerNamesArray }]}
              series={[{ data: beerRankingsArray }]}
              slotProps={{
                popper: {
                  placement: "auto-start",
                },
              }}
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
}
