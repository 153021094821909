import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { Alert } from "@mui/material";

const defaultTheme = createTheme();

export default function SignUp() {
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [usernameError, setUserNameError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [helperTextPassword, setHelperTextPassword] = React.useState("Min. 8 Character");
  const [helperTextUsername, setHelperTextUsername] = React.useState();
  const [checkpasswordError, setcheckPasswordError] = React.useState(false);
  const [helperTextCheckPassword, setHelperTextCheckPassword] =React.useState();
  const navigate = useNavigate();

  async function createNewUser(userName, password, checkPassword) {
    const res = await fetch("https://apidevelopment.de/newuser", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: userName,
        password: password,
        checkPassword: checkPassword,
      }),
    });

    if (res.status !== 200) {
      const response = await res.json();
      setLoading(false);
      setError(true);
      setPasswordError(false);
      setcheckPasswordError(false);
      setUserNameError(false);
      setErrorText(response.message);
    }

    if (res.status === 200) {
      setLoading(false);
      navigate("/");
    }

    return console.log(res);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const username = data.get("userName");
    const checkPassword = data.get("checkPassword");
    const password = data.get("password");
    if (username === "") {
      setHelperTextUsername("Username is empty");
      setUserNameError(true);
    } else if (password === "") {
      setHelperTextPassword("Password is empty");
      setPasswordError(true);
    } else if (password.length <= 8) {
      setHelperTextPassword("Password must be at least 8 Characters long");
      setPasswordError(true);
    } else if (!(password === checkPassword)) {
      setHelperTextCheckPassword("Passwords do not match");
      setcheckPasswordError(true);
    } else {
      try {
        setLoading(true);
        await createNewUser(username, password, checkPassword);
      } catch (error) {
        console.log("Error occured");
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="userName"
                  required
                  error={usernameError}
                  helperText={helperTextUsername}
                  fullWidth
                  id="userName"
                  label="Username"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={passwordError}
                  helperText={helperTextPassword}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="checkPassword"
                  type="password"
                  label="Repeat Password"
                  name="checkPassword"
                  error={checkpasswordError}
                  helperText={helperTextCheckPassword}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <RouterLink
                  to={"/"}
                  style={{ textDecoration: "none", color: "#1976d2" }}
                >
                  Already have an account? Sign in
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {error ? <Alert severity="error">{errorText}</Alert> : null}
      </Container>
    </ThemeProvider>
  );
}
