import { useEffect, useState } from "react";
import Review from "../../components/ReviewList/beerreview";
import Loader from "../../components/loader/loader";
import useDataFetch from "../../hooks/useDataFetch";

const ReviewListPage = () => {
  const dataFetching = useDataFetch();
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataFetching("getdata");
      setReviews(data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="home">{reviews && <Review reviews={reviews} />}</div>
      )}
    </div>
  );
};

export default ReviewListPage;
