// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgtu7r8MDRFa9eRdgZ1qKaCwTER1sNkHw",
  authDomain: "bierbewertungsapp.firebaseapp.com",
  projectId: "bierbewertungsapp",
  storageBucket: "bierbewertungsapp.appspot.com",
  messagingSenderId: "172812902424",
  appId: "1:172812902424:web:a77dd89a7cea1592e4e36d",
  measurementId: "G-D7DT2TT3VV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app, "gs://bierbewertungsapp.appspot.com");

